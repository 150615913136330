<template>
  <div class="card">
    <div class="card-header">
      <h2>Comissió Avaluadora: {{ convo.title }}</h2>
      <p>
        <a
          class="btn btn-sm btn-flat-dark"
          target="_blank"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Generate pdf"
          @click.stop="generatePdf"
        >
          <span :class="{'d-none': loadingPdf}">
            <i
              data-feather="file"
            />
          </span>
          <a
            class="btn btn-icon btn-icon rounded-circle btn-flat-secondary loop-loading p-0"
            :class="{'d-none': !loadingPdf}"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Loading export..."
          ><i
            data-feather="loader"
          /></a>
          Generate PDF
        </a>
      </p>
    </div>
    <div class="card-body">
      <div
        v-for="area, index in pdfEval"
        :key="index"
        class="area"
      >
        <h3 class="mt-2">
          {{ convo.areas.find(e => e.areaId == index) ? `${convo.areas.find(e => e.areaId == index).name} ${convo.areas.find(e => e.areaId == index).moderator ? `- ${convo.areas.find(e => e.areaId == index).moderator.full_name}` : ''}` : '-' }}
        </h3>

        <div
          class="row d-flex align-items-strech"
        >
          <div
            v-for="evaluator in area"
            :key="'eval-' + evaluator.id"
            class="col-sm-4"
          >
            <div class="card shadow-none bg-transparent border-secondary card__pdf-evaluators">
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-5">
                    <picture>
                      <img
                        v-if="evaluator.avatarUrl"
                        :src="evaluator.avatarUrl"
                        alt="Name"
                        class="img-fluid limit-heigth"
                      >
                      <img
                        v-else
                        :src="require('@/assets/images/avatars/9-small.png')"
                        alt="Name"
                        class="img-fluid"
                      >
                    </picture>
                  </div>
                  <div class="col-sm-7">
                    <h3>{{ evaluator.name }}</h3>
                    <p style="font-size: 17px;">
                      <strong>{{ evaluator.institution_name }}</strong>
                    </p>
                    <p style="font-size: 17px;">
                      <strong>{{ evaluator.department_name }}</strong>
                    </p>
                    <p v-if="evaluator.erc">
                      ({{ evaluator.erc.code }}) {{ evaluator.erc.name }}
                    </p>
                    <p><span class="badge bg-light-secondary">{{ convo.code }}</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      onlyRanking: false,
      loading: true,
      loadingPdf: false,
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      pdfEval: 'convo/pdfEval',
    }),
  },
  async mounted() {
    await this.$store.dispatch('convo/fetchPdfEvaluators', this.convo.id)
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 100)
  },
  methods: {
    generatePdf() {
      this.loadingPdf = true
      Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo/${this.convo.id}/get-evaluators-pdf`, {
        responseType: 'blob',
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const a = document.createElement('a')
        a.href = url
        a.download = `Pdf Evaluators ${this.convo.title}.pdf`
        a.click()
        window.URL.revokeObjectURL(url)
        this.loadingPdf = false
      }).catch(() => {
        this.loadingPdf = false
      })
    },
  },
}
</script>
