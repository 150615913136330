<template>
  <div class="card">
    <div class="card-header">
      <p>After having reviewed all the candidates that applied to the {{ convo.title }}, the panel of Humanities met in Barcelona on {{ convo.meeting_day }} reached a consensus opinion on the ranking of the applicants as follows:</p>

      <div class="form-check form-check-success form-switch d-flex align-items-start">
        <input
          id="publishContent"
          v-model="onlyRanking"
          type="checkbox"
          class="form-check-input"
        >
        <label
          class="form-check-label"
          for="publishContent"
        ><span class="ms-1">Generate pdf only with the ranking</span></label>
      </div>

    </div>
    <div class="card-body">
      <div
        v-for="area in convo.areas"
        :key="area.areaId"
      >
        <template v-if="!area.parent_id">
          <h2
            :ref="`collapse-${area.areaId}`"
            v-b-toggle="`collapseActaArea-${area.areaId}`"
            @click="loadArea(area.areaId)"
          >{{ area.name }}
            <span
              class="dis-ib float-end"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Generate pdf"
            >
              <a data-action="collapse">
                <i data-feather="chevron-down" />
              </a>
              <a
                class="btn btn-sm btn-flat-dark ms-1"
                target="_blank"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Generate pdf"
                @click.stop="generatePdf(area.areaId, area.name)"
              >
                <i data-feather="file" /> Generate empty PDF
              </a>
              <a
                class="btn btn-sm btn-flat-dark ms-1"
                :class="{'d-none': convo.signatures.find(e => e.area_id == area.areaId) || requestSign}"
                target="_blank"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Generate pdf"
                @click.stop="requestSignature(area.areaId, area.name)"
              >
                <i data-feather="file" /> Request evaluators signature
              </a>
              <a
                :class="{'d-none': convo.signatures.find(e => e.area_id == area.areaId) || !requestSign}"
                class="btn btn-sm btn-flat-dark ms-1 loop-loading"
              >
                <i data-feather="loader" />
              </a>
              <a
                :class="{'d-none': !convo.signatures.find(e => e.area_id == area.areaId)}"
                class="btn btn-sm btn-flat-dark ms-1"
                @click.stop="checkSignatures(convo.signatures.find(e => e.area_id == area.areaId).document_id)"
              >
                <i data-feather="eye" /> Check signatures
              </a>
            </span>
          </h2>
          <hr>
          <b-collapse :id="`collapseActaArea-${area.areaId}`">
            <div
              v-if="!loading"
              class="contain-tables"
            >
              <h3 class="mt-2">
                <span class="text-success">Ranked</span> <small style="float: right;">{{ rankeds.length }} items</small>
              </h3>
              <table class="table table-stripe table-acta">
                <thead>
                  <tr>
                    <th>RANK</th>
                    <th>APPLICANT</th>
                    <th v-if="convo.convo_type_id == 2">
                      ID APPLICANT
                    </th>
                    <th>STATUS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="ranked in rankeds"
                    :key="ranked.user_id"
                  >
                    <td>{{ ranked.rank }}</td>
                    <td>{{ ranked.full_name }}</td>
                    <td v-if="convo.convo_type_id == 2">
                      AC-{{ convo.id }}{{ ranked.user_id }}
                    </td>
                    <td><span v-html="ranked.status_table" /></td>
                  </tr>
                </tbody>
              </table>

              <h3 class="mt-2">
                <span class="text-info">2nd round </span> <small style="float: right;">{{ pendings.length }} items</small>
              </h3>
              <table class="table table-stripe table-acta">
                <thead>
                  <tr>
                    <th>RANK</th>
                    <th>APPLICANT</th>
                    <th v-if="convo.convo_type_id == 2">
                      ID APPLICANT
                    </th>
                    <th>STATUS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="pending in pendings"
                    :key="pending.user_id"
                  >
                    <td />
                    <td>{{ pending.full_name }}</td>
                    <td v-if="convo.convo_type_id == 2">
                      AC-{{ convo.id }}{{ pending.user_id }}
                    </td>
                    <td><span class="badge bg-light-info">2nd round </span></td>
                  </tr>
                </tbody>
              </table>

              <h3 class="mt-2">
                <span class="text-info">1st round </span> <small style="float: right;">{{ firsts.length }} items</small>
              </h3>
              <table class="table table-stripe table-acta">
                <thead>
                  <tr>
                    <th>RANK</th>
                    <th>APPLICANT</th>
                    <th v-if="convo.convo_type_id == 2">
                      ID APPLICANT
                    </th>
                    <th>STATUS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="pending in firsts"
                    :key="pending.user_id"
                  >
                    <td />
                    <td>{{ pending.full_name }}</td>
                    <td v-if="convo.convo_type_id == 2">
                      AC-{{ convo.id }}{{ pending.user_id }}
                    </td>
                    <td><span class="badge bg-light-info">1st round </span></td>
                  </tr>
                </tbody>
              </table>

              <h3
                v-if="convo.convo_type_id == 2"
                class="mt-2"
              >
                <span class="text-info">0st round </span> <small style="float: right;">{{ zeroes.length }} items</small>
              </h3>
              <table
                v-if="convo.convo_type_id == 2"
                class="table table-stripe table-acta"
              >
                <thead>
                  <tr>
                    <th>RANK</th>
                    <th>APPLICANT</th>
                    <th>
                      ID APPLICANT
                    </th>
                    <th>STATUS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="pending in zeroes"
                    :key="pending.user_id"
                  >
                    <td />
                    <td>{{ pending.full_name }}</td>
                    <td>
                      AC-{{ convo.id }}{{ pending.user_id }}
                    </td>
                    <td><span class="badge bg-light-info">0st round </span></td>
                  </tr>
                </tbody>
              </table>

              <h3 class="mt-2">
                <span class="text-dark">Panel Members</span>
              </h3>
              <div class="row row__panel-members d-flex align-items-stretch">
                <div
                  v-for="evaluator in evaluators"
                  :key="evaluator.id"
                  class="col-sm-3"
                >
                  <div class="card">
                    <div class="card-header">
                      <span class="avatar">
                        <img
                          v-if="evaluator.avatarUrl"
                          :src="evaluator.avatarUrl"
                          alt="Name"
                          class="img-fluid"
                          height="40"
                          width="40"
                        >
                        <img
                          v-else
                          :src="require('@/assets/images/avatars/9-small.png')"
                          alt="Name"
                          class="img-fluid"
                          height="40"
                          width="40"
                        >
                      </span>
                    </div>
                    <div class="card-body">
                      <h3>{{ evaluator.name }}</h3>
                      <p class="mb-0">
                        {{ evaluator.institution_name }}
                      </p>
                      <p class="mb-0">
                        {{ evaluator.department_name }}
                      </p>
                      <p v-if="evaluator.erc">
                        ({{ evaluator.erc.code }}) {{ evaluator.erc.name }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="loading"
              id="loading-bg"
              style="margin-left:-21px;"
            >
              <div
                class="loading"
              >
                <div class="effect-1 effects" />
                <div class="effect-2 effects" />
                <div class="effect-3 effects" />
              </div>
            </div>
          </b-collapse>
        </template>
      </div>
    </div>
    <CheckSignatureStatus :signature-id="selectedSignatureId" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { BCollapse } from 'bootstrap-vue'
import Vue from 'vue'
import CheckSignatureStatus from '@/views/back/partials/offcanvas/CheckSignatureStatus.vue'

export default {
  components: {
    BCollapse,
    CheckSignatureStatus,
  },
  data() {
    return {
      onlyRanking: false,
      area: null,
      loading: true,
      requestSign: false,
      selectedSignatureId: null,
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      applicants: 'convo/rankeds',
      totalRankeds: 'convo/totalRankeds',
      totalUsers: 'convo/totalUsers',
      evaluators: 'convo/rankedEvaluators',
    }),
    pendings() {
      return this.applicants.filter(e => e.rank == null && e.status > 4)
    },
    rankeds() {
      return this.applicants.filter(e => e.rank != null && e.status > 4)
    },
    firsts() {
      return this.applicants.filter(e => e.status === 4)
    },
    zeroes() {
      return this.applicants.filter(e => e.status === 2)
    },
  },
  async mounted() {
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 100)
  },
  methods: {
    async loadArea(areaId) {
      if (this.area !== areaId) {
        if (this.area != null) {
          this.$refs[`collapse-${this.area}`][0].click()
        }
        this.area = areaId
        this.loading = true
        await this.$store.dispatch('convo/fetchRankeds', { area: areaId, all: true })
        this.loading = false
      } else {
        this.area = null
      }
    },
    requestSignature(area, name) {
      Vue.swal({
        title: `Do you want to request signature to the evaluators of the ${name} area?`,
        html: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes',
      }).then(result => {
        if (result.isConfirmed) {
          this.requestSign = true
          Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo/${this.convo.id}/get-area-pdf/${area}?ranking=${this.onlyRanking}&signature=true`, {
            responseType: 'blob',
          }).then(async response => {
            await this.$store.dispatch('convo/fetchSpecific', this.convo.id)
            this.requestSign = false
            Vue.swal('Signature requested successfully', '', 'success')
          })
        }
      })
    },
    async generatePdf(area, name) {
      Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo/${this.convo.id}/get-area-pdf/${area}?ranking=${this.onlyRanking}`, {
        responseType: 'blob',
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const a = document.createElement('a')
        a.href = url
        a.download = `Acta ${this.convo.title} - ${name}.pdf`
        a.click()
        window.URL.revokeObjectURL(url)
        this.exporting = false
      })
    },
    checkSignatures(documentId) {
      this.selectedSignatureId = documentId
      this.$store.dispatch('modals/toggleCheckSignatureStatus', true)
    },
  },
}
</script>
