<template>
  <div>
    <!-- offcanvas Repeaters -->
    <div
      id="offcanvasRepeaters"
      class="offcanvas offcanvas-end offcanvas-end--big-panel d-flex"
      :class="{ show: visible }"
      :style="{ visibility: visible ? 'visible' : 'hidden' }"
      tabindex="-1"
      aria-labelledby="offcanvasRepeatersLabel"
    >
      <div class="offcanvas-header">
        <h3
          id="offcanvasRepeatersLabel"
          class="offcanvas-title"
        >
          <small>Signature resume</small>
          <br>
          <strong>View all signature status</strong>
          <br>
          <a
            class="btn btn-primary mt-1"
            @click="downloadSignedDocument"
          >
            Download signed file
          </a>
        </h3>
        <button
          type="button"
          class="btn-close text-reset btn btn-icon btn-icon rounded-circle btn-secondary"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          @click="closeOffcanva"
        />
      </div>
      <div class="offcanvas-body">
        <table
          v-if="!loading"
          class="table table-stripe"
        >
          <thead>
            <tr>
              <td style="background-color: var(--icrea-brand-bg-light);">
                Name
              </td>
              <td
                style="background-color: var(--icrea-brand-bg-light);"
              >
                Email
              </td>
              <td
                style="background-color: var(--icrea-brand-bg-light);"
              >
                Status
              </td>
            </tr>
          </thead>
          <template v-for="user, index in data">
            <tbody :key="index">
              <tr
                :key="index"
                v-b-toggle="`collapseSignatureData-${index}`"
                class="table-secondary"
              >
                <td><i data-feather="minimize-2" /> {{ user.name }}</td>
                <td>{{ user.email }}</td>
                <td v-html="user.status" />
              </tr>
            </tbody>
            <b-collapse
              :id="`collapseSignatureData-${index}`"
              :key="`collapseSignatureData-${index}`"
              tag="tbody"
              class="table"
            >
              <tr
                v-for="event in user.events"
                :key="event.type"
              >
                <td />
                <td>{{ event.created_at }}</td>
                <td>{{ event.type }}</td>
              </tr>
            </b-collapse>
          </template>
        </table>
        <div
          v-else
          class="loading"
        >
          <div class="effect-1 effects" />
          <div class="effect-2 effects" />
          <div class="effect-3 effects" />
        </div>
      </div>
    </div>
    <div
      v-if="visible"
      class="offcanvas-backdrop fade"
      :class="{ show: visible }"
      @click="closeOffcanva"
    />
  </div>
  <!-- -->
</template>

<script>
import { mapGetters } from 'vuex'
import { BCollapse } from 'bootstrap-vue'

export default {
  components: {
    BCollapse,
  },
  props: {
    signatureId: { type: String, required: true },
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      visible: 'modals/checkSignatureStatus',
      data: 'signatures/data',
    }),
  },
  watch: {
    async signatureId() {
      this.loading = true
      await this.$store.dispatch('signatures/fetchData', this.signatureId)
      this.loading = false
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 100)
    },
  },
  async mounted() {
    feather.replace({
      width: 14,
      height: 14,
    })
  },
  methods: {
    closeOffcanva() {
      this.$store.dispatch('modals/toggleCheckSignatureStatus', false)
    },
    downloadSignedDocument() {
      this.$store.dispatch('signatures/downloadSignedFile', {
        signatureId: this.signatureId,
        documentId: this.data[0] ? this.data[0].id : null,
      })
    },
  },
}
</script>
